<section class="section">
  <div class="first_section">
    <div class="title_logo">
    <img id="logo" src="/assets/images/transparent_orange_logo.svg" alt="">
    <h1>Dev'Area répond à vos besoins !</h1>
    </div>
    <div id="types">
      <div class="pan">
        <h3>Développeurs</h3>
        <p>Vous cherchez de l'aide pour un de vos projet ? D'autres développeurs avec qui échanger pour
          apprendre de nouvelles technologies ?</p>
        <a class="more" href="https://discord.devarea.fr/"><p class="more_text">En savoir plus</p>
          <p class="arrow">➜</p></a>
      </div>
      <div class="pan">
        <h3>Clients</h3>
        <p>Vous avez un projet en tête et vous avez besoin d'un ou plusieurs développeur pour le réaliser ?</p>
        <a class="more" routerLink="/missions"><p class="more_text">En savoir plus</p>
          <p class="arrow">➜</p></a>
      </div>
      <div class="pan">
        <h3>Freelances</h3>
        <p>Vous êtes un développeur professionnel ou intermédiare et vous voulez mettre en avant vos services
          contre rémunération ?</p>
        <a class="more" routerLink="/freelances"><p class="more_text">En savoir plus</p>
          <p class="arrow">➜</p></a>
      </div>
    </div>
  </div>
</section>
