<div id="container">
  <h2 *ngIf="!description">Plus sur moi...</h2>
  <div class="static">
    <p *ngIf="!description && !this.editing">Vous ne possédez pas de présentation !</p>
    <!--<div id="containerInjector"></div>-->
    <markdown emoji *ngIf="description && !this.editing" id='description'
              [data]="this.description"
       style='word-break: break-word; max-height: 400px; overflow: scroll; text-align: justify;'>
    </markdown>
    <div *ngIf="!this.editing" id="edit" class="button" (click)="edit()">Modifier</div>
  </div>
  <div class="editing" *ngIf="this.editing">
    <textarea maxlength="450" [formControl]="controller"></textarea>
    <p>Mise en forme possible avec <a href="https://support.discord.com/hc/en-us/articles/210298617-Markdown-Text-101-Chat-Formatting-Bold-Italic-Underline-" target="_blank">markdown</a> !</p>
    <p>450 charactères maximum.</p>
    <div class="line">
      <div *ngIf="!this.sending" class="button" (click)="cancel()">Annuler</div>
      <div *ngIf="!this.sending" class="button" (click)="save()">Enregistrer</div>
    </div>
    <div *ngIf="this.sending" class="disabled_button" style="margin-left: auto" (click)="save()">
      <app-loader></app-loader>
    </div>
  </div>
  <p *ngIf="this.error">Une erreur est survenue !</p>
</div>
