<span class="{{style_on_connection_change}}">
  <div id="container" [ngStyle]="{'visibility':visibility_menu}" (mouseleave)="closeMenu()">
    <span id ="top">
      <h2 class="options_disco" id="connect" (click)="connection()">Se connecter</h2>
      <h2 class="options" id="disconnect" (click)="disconnection()">Se deconnecter</h2>
      <h2 class="options" id="option" routerLink="options">Options</h2>
    </span>
    <div id="data_member">
      <h3 id="pseudo">{{name}}</h3>
      <img src="{{sourceImage}}" (click)="openMenu()" alt="">
    </div>
    <span id ="bottom">
      <h2 class="options" id="option" routerLink="options">Options</h2>
      <h2 class="options_disco" id="connect" (click)="connection()">Se connecter</h2>
      <h2 class="options" id="disconnect" (click)="disconnection()">Se deconnecter</h2>
    </span>
  </div>
</span>

<!-- routerLink="connexion" -->
