import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'description',
    templateUrl: './description.html',
    styleUrls: ['./description.scss']
})
export class Description implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
