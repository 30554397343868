<h2 [@appear]="true" >
  Work in progress...
</h2>


<!--
<div id="test">
  <div class="circle">

    <img *ngFor="let network of reseaux"
         src="{{network.url}}" alt="Image"
         class="{{returnClass(network.index, 'deg',45)}} test2"
         (click)="changeOrder(network.index)"
    >
  </div>
</div>
-->
