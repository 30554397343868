<div id="container" [@appear]="true">
  <h1 id="title_page">Espace Personnel</h1>
  <div id="first_line" *ngIf="memberInfos">
    <div id="first_colum">
      <div id="xp_card">
        <app-personnal-xp
            [avatar]="this.memberInfos.urlAvatar"
            [name]="this.memberInfos.tag"
            [next_level_xp]="this.memberInfos.next_xp_level"
            [previous_level_xp]="this.memberInfos.previous_xp_level"
            [xp]="this.memberInfos.xp"
            [previous_level]="this.memberInfos.level"
            [next_level]="this.memberInfos.level+1">
        </app-personnal-xp>
      </div>
      <app-presentation-card
          [description]="memberInfos.memberDescription">
      </app-presentation-card>
      <div id=badges_card>
        <app-badges-card [badges]="this.memberInfos.badges">
        </app-badges-card>
      </div>
    </div>
    <div id="second_colum">
      <div id="selector">
        <p *ngIf="this.status != tabs.Mission" style="color:grey;" (click)="switch(tabs.Mission)">Missions</p>
        <p *ngIf="this.status == tabs.Mission">Missions</p>
        <div class="separator"></div>
        <p *ngIf="this.status != tabs.Freelance" style="color:grey;" (click)="switch(tabs.Freelance)">
          Freelance</p>
        <p *ngIf="this.status == tabs.Freelance">Freelance</p>
        <div class="separator" *ngIf="this.memberInfos!.avis.length != 0 || this.status == tabs.Avis"></div>
        <p *ngIf="this.status != tabs.Avis && (this.memberInfos!.avis.length != 0 || this.status == tabs.Avis)" style="color:grey;" (click)="switch(tabs.Avis)">
          Avis</p>
        <p *ngIf="this.status == tabs.Avis && (this.memberInfos!.avis.length != 0 || this.status == tabs.Avis)">Avis</p>
      </div>
      <hr/>

      <app-missions-wiewer *ngIf="this.status == tabs.Mission"
                           [controller]="true"
                           [missions]="this.memberInfos!.missions_list"></app-missions-wiewer>
      <app-freelance-wiewer *ngIf="this.status == tabs.Freelance"
                            [controller]="true"
                            [freelance]="this.memberInfos!.freelance"></app-freelance-wiewer>

      <div id="avis_grid" *ngIf="this.status == tabs.Avis && this.memberInfos!.avis.length != 0" >
        <app-avis-card *ngFor="let avi of this.memberInfos!.avis "
                       [@appear]="true"
                       [avi]="avi"></app-avis-card>
      <h2 [@appear]="true" *ngIf="this.status == tabs.Avis && this.memberInfos!.avis.length == 0" style="text-align: center">Ce membre ne possède pas d'avis.</h2>

    </div>
  </div>
  <div *ngIf="!memberInfos" class="disabled_button">
    <app-loader></app-loader>
  </div>
</div>
