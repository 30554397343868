<h1>Développeurs</h1>
<section>
    <div class="first_section">
        <p>
            Tout d'abord l'entraide entre les membres: le serveur regroupe une grande communauté de
            développeurs
            passionnés. Ce sont des développeurs qui n'ont pas spécialement fait du développement leur métier. Le
            serveur
            leur permet de s'abreuver dans une fontaine de connaissances et de faire des rencontres avec d'autres
            développeurs, que ce soit pour un problème directement lié au code, ou tout simplement pour échanger avec de
            nouvelles personnes de milieux différents.
        </p>
    </div>
</section>