<h1 id="title" [@appear]="true">TODO-LIST</h1>
<div id="container">
    <div class="column">
        <h1>Back End</h1>
        <p>
            • Faire un sytème de stats ( c'est à dire compter le nombre de message, à but de savoir si un membre est
            actif ou non ou encore savoir quel langage est le plus actif etc... )
        </p>
        <p>
            • De ce système de stats créer de nouveaux Badges pour les membres
        </p>
        <p>
            • De ce système de stats créer plus de requêtes pour les stats
        </p>
    </div>
    <div class="column">
        <h1>Front End</h1>
        <p>
            • Avec les nouvelles requêtes du back ajouter des parties dans l'onglet Stats ( comme des transitions entre
            les onglets )
        </p>
        <p>
            • Rendre le site plus fluide avec quelques animation
        </p>
        <p>
            • Patch un bug qui n'affiche aucune information dans l'onglet option
        </p>
        <p>
            • Patch les copies de tag dans les missions.
        </p>
        <p>
            • Dans les missions-card rendre les infos-bulles plus propre et avec le theme du site.
        </p>
    </div>
</div>
