<a *ngIf="preview" id="container" routerLink="/member-profile"
   [queryParams]="{member_id: this.preview?.member_id, open: tabs.Freelance}"
   (click)="openProfile()">
  <div id="first_line">
    <div id="member">
      <img src="{{this.preview!.avatar_url}}">
    </div>
    <p id="name">{{ this.preview!.name }}</p>
    <markdown emoji id="description" [data]="this.preview!.description + '...'"></markdown>
  </div>
  <div id="abilities">
    <h2 class="ability">Compétences:</h2>
    <p class="ability" *ngFor="let ability of this.abilities; let i= index"><strong>•</strong> {{ ability }}</p>
  </div>
</a>
