<div id="popup" [@appear]="true" *ngIf="currentClicked !== '' " (mouseenter)="enterInPopup()">
    <div class="line">
        <h1>{{ currentClicked }}</h1>
        <div class="button" (click)="leavePopup()">X</div>
    </div>
    <markdown [data]="currentPopupText" *ngIf="currentPopupText !== ''"></markdown>
    <app-loader *ngIf="currentPopupText === ''"></app-loader>
</div>

<div class="container" [@appear]="true">
    <div class="large_component">
        <h1 class="title">
            Les challenges
        </h1>
        <p>
            Les challenges, tout d'abord qu'est ce que c'est ?<br><br>

            Un challenge est un petit défi algorithmique et/ou de programmation intéractif proposé par Dev'Area.<br><br>

            Le fonctionnement est assez simple, vous avez simplement à télécharger le client dans votre language préféré
            pour commencer les défis !

            Le client vous permettra de dialoguer avec le serveur pour recevoir les jeux de tests et valider vos
            réponses
            !
        </p>
        <a class="button" routerLink="download">
            Télécharger un client
        </a>
    </div>
    <div id="bottom_container">
        <div id="map_section">
            <h1 class="title">Challenge Map</h1>
            <div id="mapContainer">
                <p style="color:var(--main-color); font-style: italic;" *ngIf="isConnected === 'not_connected'">
                    Connectez-vous pour voir votre progression.</p>
                <div class="levelContainer" *ngFor="let i of eachLevel()">
                    <div class="challengeContainer">
                        <div *ngFor="let challenge of getChallengesOfLevel(i)">
                            <p [id]="challenge" class="challenge" (click)="click(challenge)"
                               (mouseenter)="enter(challenge)"
                               (mouseleave)="leave(challenge)">{{ getChallengeStatus(challenge) + challenge }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="activity_section">
            <h1 class="title">Activité récente</h1>
            <div id="activityContainer">
                <div class="activityCard" *ngFor="let card of challengeActivity">
                    <img [src]="card.avatarUrl" alt="">
                    <p>{{ card.name }} a validé <strong style="font-style: italic">{{ card.challenge.name }}</strong> il
                        y a {{ transform(card.challenge.date) }}.</p>
                </div>
            </div>
        </div>
    </div>
</div>
