import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-dev-area',
  templateUrl: './join-dev-area.html',
  styleUrls: ['./join-dev-area.scss']
})
export class JoinDevArea implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
