<div id="container" *ngIf="member_profile" [@appear]="true">
    <h1 id="title_page">{{this.member_profile.name}}</h1>
    <div id="first_line" *ngIf="member_profile">
        <div id="first_colum">
            <div id="xp_card">
                <a id="button_contact" class="button" href="https://www.discordapp.com/users/{{member_id}}">🗨️</a>
                <app-personnal-xp
                        [avatar]="this.member_profile.urlAvatar"
                        [name]="this.member_profile.tag"
                        [next_level_xp]="this.member_profile.next_xp_level"
                        [previous_level_xp]="this.member_profile.previous_xp_level"
                        [xp]="this.member_profile.xp"
                        [previous_level]="this.member_profile.level"
                        [next_level]="this.member_profile.level+1">
                </app-personnal-xp>
            </div>

            <div id="descriptionContainer">
                <h2 *ngIf="!this.member_profile!.memberDescription">Plus sur moi...</h2>
                <p *ngIf="!this.member_profile!.memberDescription">Ce membre ne possède pas de présentation !</p>
                <markdown emoji *ngIf="this.member_profile.memberDescription"
                          [data]="this.member_profile.memberDescription"
                   style='word-break: break-word; max-height: 400px; overflow: scroll;text-align: justify;'></markdown>
            </div>

            <div id=badges_card>
                <app-badges-card
                        [badges]="member_profile.badges">
                </app-badges-card>
            </div>
        </div>
        <div id="second_colum">
            <div id="selector">
                <p *ngIf="this.status != tabs.Mission" style="color:grey;" (click)="switch(tabs.Mission)">Missions</p>
                <p *ngIf="this.status == tabs.Mission">Missions</p>
                <div class="separator"></div>
                <p *ngIf="this.status != tabs.Freelance" style="color:grey;" (click)="switch(tabs.Freelance)">
                    Freelance</p>
                <p *ngIf="this.status == tabs.Freelance">Freelance</p>
                <div class="separator" *ngIf="this.member_profile!.avis.length != 0 || this.status == tabs.Avis"></div>
                <p *ngIf="this.status != tabs.Avis && (this.member_profile!.avis.length != 0 || this.status == tabs.Avis)" style="color:grey;" (click)="switch(tabs.Avis)">
                    Avis</p>
                <p *ngIf="this.status == tabs.Avis && (this.member_profile!.avis.length != 0 || this.status == tabs.Avis)">Avis</p>
            </div>
            <hr/>

            <app-missions-wiewer *ngIf="this.status == tabs.Mission"
                                 [missions]="member_profile.missions_list"></app-missions-wiewer>
            <app-freelance-wiewer *ngIf="this.status == tabs.Freelance"
                                  [freelance]="this.member_profile!.freelance"></app-freelance-wiewer>
            <div id="avis_grid" *ngIf="this.status == tabs.Avis && this.member_profile!.avis.length != 0" >
                <app-avis-card *ngFor="let avi of this.member_profile!.avis "
                               [@appear]="true"
                               [avi]="avi"></app-avis-card>
            </div>
            <h2 [@appear]="true" *ngIf="this.status == tabs.Avis && this.member_profile!.avis.length == 0" style="text-align: center">Ce membre ne possède pas d'avis.</h2>
        </div>
    </div>
    <a *ngIf="memberConnectedId == member_id" class="button" routerLink="/options" (click)="openOptions()">Gérer votre profil...</a>
</div>

<div class="disabled_button" *ngIf="!member_profile">
    <app-loader></app-loader>
</div>
