import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fil-info',
  templateUrl: './fil-info.html',
  styleUrls: ['./fil-info.scss']
})
export class FilInfo implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
