<div id="container" [@appear]="true">
    <div id="informations">
        <h1>
            Le Staff
        </h1>
        <p>
            Le serveur <strong>Dev'Area</strong> a été ouvert en 2020. Aujourd'hui l'équipe du staff, composée de 10
            membres, veille toujours à ce que la communauté puisse évoluer, et ajoute régulièrement de nouvelles
            fonctionnalitées.
        </p>
    </div>
    <div class="slider" *ngIf="staff">
        <div class="slide-track">
            <div class="slide">
                <div class="parent">
                    <app-staff-card *ngFor="let person of staff"
                                    [staff_name]="person.name"
                                    [staff_bio]="person.bio"
                                    [staff_urlAvatar]="person.urlAvatar"
                                    [staff_member_id]="person.id"
                                    [staff_id]="person.idCss">
                    </app-staff-card>
                </div>
            </div>
            <div class="slide">
                <div class="parent" id="at-remove">
                    <app-staff-card *ngFor="let person of staff"
                                    [staff_name]="person.name"
                                    [staff_bio]="person.bio"
                                    [staff_urlAvatar]="person.urlAvatar"
                                    [staff_member_id]="person.id"
                                    [staff_id]="person.idCss">
                    </app-staff-card>
                </div>
            </div>
        </div>
    </div>
    <div class="disabled_button" *ngIf="!staff" style="margin-top: 120px">
        <app-loader></app-loader>
    </div>
</div>
