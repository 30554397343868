import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-types-description',
  templateUrl: './types-description.html',
  styleUrls: ['./types-description.scss']
})
export class TypesDescription implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
