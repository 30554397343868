<div class="info">
  <h2>Informations</h2>
  <hr>
  <!--
  <h3>20/01/2021</h3>
  <p>Prof de Gaetan</p><hr>
  <h3>22/01/2020</h3>
  <p>Le Meetup sur le Java</p><hr>
  <h3>25/11/17543</h3>
  <p>Un truc irl de ouf, genre on a jamais vu ca</p>-->
  <h3>02/2022</h3>
  <h3>Mise en ligne du site Web.</h3>
</div>
