<div *ngIf="this.mission" id="container" [@appear]="true">
    <div id="mission_content">
        <h1>{{mission!.title}}</h1>
        <markdown emoji id="description">{{mission!.description}}</markdown>
        <p id="date">{{getCreationDate()}}</p>
    </div>
    <div id="infos">
        <div id="memberInfo" (click)="openProfile()">
            <img src="{{mission!.avatarURL}}">
            <p><strong>{{mission!.memberTag}}</strong></p>
        </div>
        <hr>
        <p *ngIf="mission!.deadLine != 'Non définie'">DeadLine : {{mission!.deadLine}}</p>
        <p *ngIf="mission!.deadLine != 'Non définie'">•</p>
        <p *ngIf="mission!.support != 'Non définie'">Support : {{mission!.support}}</p>
        <p *ngIf="mission!.support != 'Non définie'">•</p>
        <p *ngIf="mission!.level != 'Non définie'">Difficulté : {{mission!.level}}</p>
        <p *ngIf="mission!.level != 'Non définie'">•</p>
        <p *ngIf="mission!.language != 'Non définie'">Langage à utiliser : {{mission!.language}}</p>
        <p *ngIf="mission!.language != 'Non définie'">•</p>
        <p>Budget 💰: {{mission!.budget}}</p>
        <p>•</p>
        <p *ngIf="mission!.lastUpdate == '1'">Dernière validation il y a {{mission!.lastUpdate}} jour</p>
        <p *ngIf="mission!.lastUpdate != '1'">Dernière validation il y a {{mission!.lastUpdate}} jours</p>

        <div *ngIf="!loading && !took && this.mission.memberID != memberID" class="button" (click)="tookMission()">
            Prendre la mission
        </div>
        <div *ngIf="loading && !took && !comfirm" class="disabled_button">
            <app-loader></app-loader>
        </div>
        <div *ngIf="took" class="disabled_button">Vous suivez cette mission !</div>
        <p *ngIf="took">Vous suivez cette mission ! Un channel dédier à votre discussion avec le client a donc été créé
            ! Retournez tout simplement sur le serveur discord !</p>


        <div *ngIf="this.mission.memberID == memberID  && !comfirm" class="button" (click)="buttonDeleteClick()">
            Supprimer cette mission
        </div>
        <hr *ngIf="comfirm">
        <p *ngIf="comfirm">Voulez-vous vaiment supprimer cette mission ?</p>
        <div style="display: flex; gap: 20px;">
            <div *ngIf="comfirm && !this.loading" class="button" (click)="cancel()">Non</div>
            <div *ngIf="comfirm && !this.loading" class="button" (click)="delete()">Oui</div>
        </div>
        <div *ngIf="loading && comfirm" class="disabled_button">
            <app-loader></app-loader>
        </div>
    </div>
</div>

<div *ngIf="!this.mission" class="disabled_button">
    <app-loader></app-loader>
</div>
