<div class="container" [@appear]="true">
    <div class="large_component">
        <h1 class="title">
            Les missions payantes
        </h1>
        <p>
            Cet espace représente le channel <a target="_blank" rel="noopener noreferrer"
                                                href="https://discord.com/channels/768370886532137000/943817647060025354">
            <span class="discord_channel"># 💰・missions-payantes</span></a> du serveur discord. Vous pourrez y retrouver
            les missions postées par les utilisateurs.
        </p>
        <a class="button" routerLink="/mission-creator">Créer sa mission</a>
    </div>
    <div *ngIf="missions_list" id="missions">
        <app-mission-preview
            id="mission"
            *ngFor="let mission of missions_list"
            [title]="mission.title"
            [id]="mission.id"
            [last_update]="mission.lastUpdate"
            [description]="mission.description"
            [posterUrl]="mission.avatarURL"
            [budget]="mission.budget"
        ></app-mission-preview>
    </div>
    <div class="loading">
        <div *ngIf="!loading && !end " class="button" (click)="this.missionsService.fetch_more()">Charger plus...</div>
        <div *ngIf="loading" class="disabled_button">
            <app-loader></app-loader>
        </div>
        <div id="bottom"></div>
    </div>
</div>
