<div class="container" [@appear]="true">
    <div class="large_component">
        <h1 class="title">
            Les freelances
        </h1>
        <p>
            Cet espace représente le channel <a target="_blank" rel="noopener noreferrer"
                                                href="https://discord.com/channels/768370886532137000/856081355309842452">
      <span class
                    ="discord_channel"># 🛠・free-lance</span></a> du serveur discord. Vous pourrez y retrouver
            les développeurs freelance.
        </p>
        <a class="button" routerLink="/freelance-creator">Créer sa page Freelance</a>
    </div>
    <div id="freelances_container" *ngIf="freelances">
        <app-freelance-preview
                *ngFor="let freelance of this.freelances; let i= index"
                [preview]="freelance"></app-freelance-preview>
    </div>
    <div *ngIf="!loading && !end " class="button" (click)="this.freelancesService.fetch_more()">Charger plus...</div>
    <div *ngIf="loading" style="cursor: wait;" class="disabled_button">
        <app-loader></app-loader>
    </div>
</div>
