<div id="global_container" [@appear]="true">
    <h1>Comment se connecter ?</h1>
    <div id="first_line">
        <div id="not_on_server">
            <h2>Vous n'êtes pas sur le serveur Discord ?</h2>
            <p>Pour pouvoir se connecter au site il vous faut obligatoirement être sur le serveur discord de Dev'Area ! Une fois sur le serveur suivez les étapes classiques.</p>
            <a href="https://discord.devarea.fr/">
                <div class="button">Rejoindre</div>
            </a>
        </div>
        <div id="on_server">
            <h2>Vous êtes sur le serveur discord ?</h2>
            <p>
                Il vous suffis d'utiliser la commande <strong>/auth</strong> dans le channel
                <a target="_blank" rel="noopener noreferrer" href="https://discord.com/channels/768370886532137000/768783370884612098"><span
                        class="discord_channel">🔣・commandes-bot&nbsp;</span></a> puis de suivre les instructions envoyées par le bot !
            </p>
        </div>
    </div>
</div>