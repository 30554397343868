<a id="preview" (click)="open()"  routerLink="/mission" [queryParams]="{id: this.id}">
    <p id="title">{{title}}</p>
    <p>{{description}}</p>
    <div id="last_line">
        <p *ngIf="last_update == '1'" id="last_update">Il y a {{last_update}} jour...</p>
        <p *ngIf="last_update != '1'" id="last_update">Il y a {{last_update}} jours...</p>
        <div id="group">
            <p>{{budget}}</p>
            <img src="{{posterUrl}}">
        </div>
    </div>
</a>
