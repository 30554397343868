import { Component } from '@angular/core';

@Component({
  selector: 'app-challenges-download',
  templateUrl: './challenges-download.html',
  styleUrls: ['./challenges-download.scss']
})
export class ChallengesDownload {

}
