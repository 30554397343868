<div id="container">
  <img src="{{avatar}}">
  <div id="text_part">
    <h1 id="name">{{name}}</h1>
    <div id="xp_part">
      <h3 id="previous">{{previous_level}}</h3>
      <h3 id="xp">XP-{{xp}}</h3>
      <h3 id="next">{{next_level}}</h3>
    </div>
    <div class="progress-bar">
      <span class="bar">
        <span class="progress" [ngStyle]="{width:getLong()+'%'}"></span>
      </span>
    </div>

  </div>
</div>
