import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'commands-available',
  templateUrl: './commands-available.html',
  styleUrls: ['./commands-available.scss']
})
export class CommandsAvailable implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
