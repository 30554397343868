<div class="large_component">
    <h1 class="title">
        Téléchargement des clients
    </h1>
    <table>
        <thead>
        <tr>
            <th>Language</th>
            <th>Sources du client</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Java</td>
            <td>
                <a class="link" href="https://devarea.fr/assets/challenges/clients/java/client_java.zip">zip</a>.
            </td>
        </tr>
        <tr>
            <td>Python</td>
            <td>
                <a class="link" href="https://devarea.fr/assets/challenges/clients/python/client_python.zip">zip</a>.
            </td>
        </tr>
        <tr>
            <td>C/C++</td>
            <td>
                <a class="link" href="https://devarea.fr/assets/challenges/clients/c/client_c.zip">zip</a> using cmake.
            </td>
        </tr>
        <tr>
            <td>NodeJS</td>
            <td>
                Work in progress...
            </td>
        </tr>
        <tr>
            <td>Autre...</td>
            <td>
                N'hésitez pas à venir contribuer !
                <br>S'adresser à Edifay.
            </td>
        </tr>
        </tbody>
    </table>
    <h2 class="title">Si vous rencontrez le moindre problème avec un client
        <a href="https://discord.com/channels/768370886532137000/768783443743735840"
           class="discord_channel">#support</a>
        .</h2>
</div>
