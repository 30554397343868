<div class="informations">
  <h2 id="top">
    <img src="../../../../../../assets/images/logo.svg" alt="bot">
    Voici le Bot de DevArea ! Il apporte de nouvelles fonctionnalités au serveur.
  </h2>

  <h3>Commandes globales:</h3>
  <p>
    <strong>//help</strong> -> donne cette liste.<br>
    <strong>//ping</strong> -> donne le temps de latence du bot.<br>
    <strong>//start</strong> -> envoi un message qui permet de bien commencer dans un langage.
  </p>
  <h3>Communications:</h3>
  <p>
    <strong>//devhelp</strong> -> mentionne les membres ayant pris le rôle Dev'Helper.<br>
    <strong>//ask</strong> -> donne les informations pour bien poser une question.<br>
    <strong>//meetup</strong> -> permet de créer un meetup autour d'un sujet.<br><br>
    Les channels d'aides vocaux sont créé automatiquement par le bot lors de la connexion au channel vocal : "Votre
    channel d'aide".
  </p>
  <h3>XP:</h3>
  <p>
    <strong>//rank</strong> -> donne l'xp et le rang de la personne (mentionable).<br>
    <strong>//leaderboard</strong> -> permet de voir le classement des membres du serveur en xp.<br>
    <strong>//askreward [mention de la personne que vous avez aidé]</strong> -> Si vous ou plusieurs personnes avez aidé
    quelqu'un à résoudre son problème, vous pouvez lui demander une récompense (en xp) avec cette commande.<br>
    <strong>//givereward</strong> -> Si une ou plusieurs personnes vous ont aidé à résoudre votre problème et que vous
    désirez lui donner une récompense (en xp), vous pouvez le faire avec cette commande. Les chiffres : pour 10xp donné
    50xp reçu, ici le nombre d'xp est défini et inchangeable.
  </p>

  <h3>Développeurs <-> Clients :</h3>
  <p>
    <strong>//mission</strong> -> permet de gérer les missions créées.<br>
    <strong>//freelance</strong> -> permet de gérer le message freelance.<br><br>
    <strong>creationMissions</strong> & <strong>creationFreeLance</strong> -> ne se lancent pas comme des commandes
    classiques, une réaction dans le channel : <a target="_blank" rel="noopener noreferrer"
                                                  href="https://discord.com/channels/768370886532137000/943817647060025354"><span
    class="discord_channel"># 💰・missions-payantes</span></a> & <a target="_blank" rel="noopener noreferrer"
                                                                   href="https://discord.com/channels/768370886532137000/856081355309842452"><span
    class="discord_channel"># 🛠・free-lance</span></a> permet de commencer la commande.
  </p>
</div>
