<div [ngClass]="this.component.page_theme" id="container">
    <header [ngClass]="this.header_status">
        <div id="bar">
            <img id="button" src="assets/images/icon-menu.png" (click)="openMenu()"/>
        </div>
        <a (click)="close()" routerLink="menu" routerLinkActive="active">
            <p>Menu</p>
        </a>

        <a (click)="close()" routerLink="missions" routerLinkActive="active">
            <p>Missions</p>
        </a>

        <a (click)="close()" routerLink="freelances" routerLinkActive="active">
            <p>Freelances</p>
        </a>

        <a (click)="close()" routerLink="challenges" routerLinkActive="active">
            <p>Challenges</p>
        </a>


        <a (click)="close()" routerLink="stats" routerLinkActive="active">
            <p>Stats</p>
        </a>


        <a (click)="close()" routerLink="staff" routerLinkActive="active">
            <p>Staff</p>
        </a>

        <app-theme-switcher></app-theme-switcher>
        <div id="croix_container">
            <img id="close_button" (click)="close()" src="assets/images/close.png">
        </div>
    </header>
</div>
